import React from 'react';
import { Group } from 'react-konva';
export const Portal = ({ selector, enabled, children }) => {
    const outer = React.useRef(null);
    const inner = React.useRef(null);
    const safeRef = React.useRef();
    const shouldMove = enabled !== null && enabled !== void 0 ? enabled : true;
    React.useLayoutEffect(() => {
        if (!outer.current || !inner.current) {
            return;
        }
        safeRef.current = inner.current;
        const stage = outer.current.getStage();
        const newContainer = stage.findOne(selector);
        if (shouldMove && newContainer) {
            inner.current.moveTo(newContainer);
        }
        else {
            inner.current.moveTo(outer.current);
        }
        outer.current.getLayer().batchDraw();
        if (newContainer) {
            newContainer.getLayer().batchDraw();
        }
    }, [selector, shouldMove]);
    React.useEffect(() => {
        return () => {
            var _a;
            (_a = safeRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
        };
    }, []);
    return (React.createElement(Group, { name: "_outer_portal", ref: outer },
        React.createElement(Group, { name: "_inner_portal", ref: inner }, children)));
};
