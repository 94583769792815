import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { checkIfCanScroll } from './checkIfCanScroll';
import { getLinkHash } from './getLinkHash';
import { IHashLinkBaseProps } from './IHashLinkProps';
import LinkState from './LinkState';
import { reset } from './reset';
import { scrollIntoView } from './scrollIntoView';

const state = LinkState;
type INavHashLinkProps = IHashLinkBaseProps & NavLinkProps;

const NavHashLink: React.FC<INavHashLinkProps> = React.forwardRef<
  HTMLAnchorElement,
  INavHashLinkProps
>((props, ref) => {
  const { scroll, smooth, timeout, elementId, children, onClick, ...rest } =
    props;
  const linkHash = getLinkHash(props);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    reset();
    state.hashFragment = elementId ? `#${elementId}` : linkHash;
    if (onClick) {
      onClick(e);
    }

    const canScroll = checkIfCanScroll(e, props);
    if (canScroll) {
      scrollIntoView(smooth, scroll, timeout);
    }
  };

  return (
    <NavLink ref={ref} onClick={handleClick} {...rest}>
      {children}
    </NavLink>
  );
});

export default NavHashLink;
